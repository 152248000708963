import api from '../../lib/api'

export default {
    namespaced: true,
    state: {
        list: [],
        names: [],
        materialsId: 0,
        productsId: 0,
        defaults: {},
        loadingList: false,
        loadingNames: false,
        emptyItem: {
            id: 0,
            name: "",
            materials: 'all',
            products: 'all',
            notes: '',
            materialsCategories: [],
            productsCategories: [],
            materialsItems: [],
            productsItems: [],
        }
    },
    getters: {
        list: state => state.list,
        names: state => state.names,
        countList: state => state.list?.length,
        countNames: state => state.names?.length,
        materials: state => state.names.filter(item => item.materials != 'none'),
        products: state => state.names.filter(item => item.products != 'none'),
        materialsCount: (state, getters) => getters.materials.length,
        productsCount: (state, getters) => getters.products.length,
        defaults: state => state.defaults,
        materialsId: state => state.materialsId,
        materialsName: (state, getters) => getters.materials.find((item) => item.id == state.materialsId)?.name,
        productsId: state => state.productsId,
        productsName: (state, getters) => getters.products.find((item) => item.id == state.productsId)?.name,
        loadingList: state => state.loadingList,
        loadingNames: state => state.loadingNames,
        loading: state => state.loadingList || state.loadingNames,
        loadedList: (state, getters) => getters.countList > 0,
        loadedNames: (state, getters) => getters.countNames > 0,
        emptyItem: state => state.emptyItem,
    },
    mutations: {
        setList(state, list) {
            state.list = list
        },
        setNames(state, names) {
            state.names = names
        },
        setDefaults(state, defaults) {
            state.defaults = defaults
        },
        setMaterialsId(state, materialsId) {
            state.materialsId = materialsId
        },
        setProductsId(state, productsId) {
            state.productsId = productsId
        },
        startLoadingList(state) {
            state.loadingList = true
        },
        stopLoadingList(state) {
            state.loadingList = false
        },
        startLoadingNames(state) {
            state.loadingNames = true
        },
        stopLoadingNames(state) {
            state.loadingNames = false
        },
        clear(state) {
            state.list = []
            state.materialsId = 0
            state.productsId = 0
            state.defaults = {}
        }
    },
    actions: {
        getList(context, force = false) {
            // Склады загружаются только один раз, при первом обращении к ним
            // Если force = true, то загрузка будет произведена в любом случае
            if (context.getters.loadedList && !force) return

            // Если загрузка уже идет, то ничего не делаем
            if (context.getters.loadingList) return

            context.commit('startLoadingList')
            return api.post("storages/get_list").then((response) => {
                context.commit('setList', response.data.storages)
                context.commit('setDefaults', response.data.defaults)
                context.commit('setNames', response.data.storages.map((item) => ({
                    id: item.id,
                    name: item.name,
                    materials: item.materials,
                    products: item.products,
                })))

                // Устанавливаем значения выбранных складов
                // - если они еще не установлены
                // - если они не существуют в списке складов (при удалении или изменении параметров склада)
                if (context.getters.materialsId == 0
                    || !context.getters.materials.find((item) => item.id == context.getters.materialsId)
                ) {
                    context.commit('setMaterialsId', response.data.materialsId)
                }
                if (context.getters.productsId == 0
                    || !context.getters.products.find((item) => item.id == context.getters.productsId)
                ) {
                    context.commit('setProductsId', response.data.productsId)
                }

                context.commit('stopLoadingList')
            })
        },
        getNames(context, force = false) {
            // Склады загружаются только один раз, при первом обращении к ним
            // Если force = true, то загрузка будет произведена в любом случае
            if (context.getters.loadedNames && !force) return

            // Если загрузка уже идет, то ничего не делаем
            if (context.getters.loadingNames) return

            context.commit('startLoadingNames')
            return api.post("storages/get_names").then((response) => {
                context.commit('setNames', response.data.storages)
                context.commit('setDefaults', response.data.defaults)

                // Устанавливаем значения выбранных складов
                // - если они еще не установлены
                // - если они не существуют в списке складов (при удалении или изменении параметров склада)
                if (context.getters.materialsId == 0
                    || !context.getters.materials.find((item) => item.id == context.getters.materialsId)
                ) {
                    context.commit('setMaterialsId', response.data.materialsId)
                }
                if (context.getters.productsId == 0
                    || !context.getters.products.find((item) => item.id == context.getters.productsId)
                ) {
                    context.commit('setProductsId', response.data.productsId)
                }

                context.commit('stopLoadingNames')
            })
        },
        setMaterialsId(context, storageId) {
            if (!storageId) return
            if (context.getters.materialsId == storageId) return
            if (!context.getters.materials.find((item) => item.id == storageId)) return

            context.commit('setMaterialsId', storageId)

            return api.post("storages/update_selection", {
                type: "materials",
                id: storageId,
            })
        },
        setProductsId(context, storageId) {
            if (!storageId) return
            if (context.getters.productsId == storageId) return
            if (!context.getters.products.find((item) => item.id == storageId)) return

            context.commit('setProductsId', storageId)

            return api.post("storages/update_selection", {
                type: "products",
                id: storageId,
            })
        },
        clear(context) {
            context.commit('clear')
        },
    }
}