import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './lib/router.js'
import vuetify from './plugins/vuetify'
import api from './lib/api'
import { init, setUserId, identify, Identify } from '@amplitude/analytics-browser'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.config.productionTip = false
// console.clear()


// Amplitude
init('f62fa84d6158eba06d3193f3c9481f21', null, {
	// optional configuration options
	saveEvents: true,
	includeUtm: true,
	includeReferrer: true
})

// Google Recaptcha
Vue.use(VueReCaptcha, {
	siteKey: '6Lf5UPMjAAAAAIHeZWHekvIiqDt-eeZuprIYxhEZ',
	loaderOptions: {
		autoHideBadge: true
	}
})

// Turn off production tip
Vue.config.productionTip = false

// Read auth token from local storage
if (localStorage.getItem('token')) {
	api.post('auth/authorize').then(response => {

		// Initialize Vue
		var vm = new Vue({
			router,
			vuetify,
			store,
			render: h => h(App),
		}).$mount('#app')

		window.vm = vm

		store.dispatch("login", response.data)

		// // Load initial data (storages, statuses)
		// store.dispatch('loadInitialData')

		// // Amplitude set user id
		// setUserId(response.data.user.email)

		// // Amplitude set user property authorized to 1
		// const identifyObj = new Identify()
		// identifyObj.set('authorized', 1)
		// identify(identifyObj)

		// // Carrotquest set user id and update properties
		// carrotquest.auth(response.data.user.id, response.data.carrotquestHash)
		// carrotquest.identify([
		// 	{
		// 		op: "update_or_create",
		// 		key: "$email",
		// 		value: response.data.user.email,
		// 	},
		// 	{
		// 		op: "update_or_create",
		// 		key: "$name",
		// 		value: response.data.user.name,
		// 	},
		// 	{
		// 		op: "update_or_create",
		// 		key: "company_admin",
		// 		value: response.data.user.companyAdmin,
		// 	},
		// 	{
		// 		op: "update_or_create",
		// 		key: "company_id",
		// 		value: response.data.user.companyId,
		// 	},
		// 	{
		// 		op: "update_or_create",
		// 		key: "company_name",
		// 		value: response.data.user.companyName,
		// 	},
		// 	{
		// 		op: "update_or_create",
		// 		key: "plan",
		// 		value: response.data.user.plan,
		// 	},
		// 	{
		// 		op: "update_or_create",
		// 		key: "trial_days",
		// 		value: response.data.user.trialDays,
		// 	},
		// ])
	})
}
else {
	// Initialize Vue
	var vm = new Vue({
		router,
		vuetify,
		store,
		render: h => h(App),
	}).$mount('#app')

	window.vm = vm
}