import Vue from 'vue'
import Vuex from 'vuex'
import api from '../lib/api'
import router from '../lib/router'
import colors from 'vuetify/lib/util/colors'
import { identify, Identify, setUserId } from "@amplitude/analytics-browser"
import dialog from './modules/dialog'
import units from './modules/units'
import categories from './modules/categories'
import suppliers from './modules/suppliers'
import storages from './modules/storages'
import materials from './modules/materials'
import products from './modules/products'
import resources from './modules/resources'
import customers from './modules/customers'
import currencies from './modules/currencies'
import statuses from './modules/statuses'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		dialog,
		units,
		categories,
		suppliers,
		storages,
		materials,
		products,
		resources,
		customers,
		currencies,
		statuses
	},
	state: {
		user: null,
		authorized: false,
		adminInfo: null,
		adminToken: '',
		production: [],
		purchases: [],
		transfers: [],
		writeoffs: [],
		orders: [],
		audits: [],
		companies: [],
		prefs: {},
		reports: {
			revenuePeriod: 'week',
			checkPeriod: 'week',
			productsPeriod: 'oneMonth',
			categoriesPeriod: 'oneMonth',
			materialsValuePeriod: 'week',
			productsValuePeriod: 'week',
			productsMadePeriod: 'week',
			materialsUsedPeriod: 'week',
			materialsBoughtPeriod: 'week',
			materialsTurnoverRate: 'low',
			productsTurnoverRate: 'low',
		},
		pathBeforeLogin: '',
		colors: [
			colors.red.lighten3,
			colors.blue.lighten3,
			colors.green.lighten3,
			colors.amber.lighten3,
			colors.teal.lighten3,
			colors.lime.lighten3,
			colors.purple.lighten3,
			colors.indigo.lighten3,
		],
		snackbar: [],
		snackNum: 0,
		needReload: false
	},
	getters: {
		authorized: state => state.authorized,
		user: state => state.user,
		userId: state => state.user?.id,
		userName: state => state.user?.name,
		userEmail: state => state.user?.email,
		userCompanyName: state => state.user?.companyName,
		userPlan: state => state.user?.plan,
		userPastdue: state => state.user?.pastdue,
		userTrialDays: state => state.user?.trialDays,
		userCompanyAdmin: state => state.user?.companyAdmin,
		userGlobalAdmin: state => state.user?.globalAdmin,
		pathBeforeLogin: state => state.pathBeforeLogin,
		production: state => state.production,
		purchases: state => state.purchases,
		transfers: state => state.transfers,
		writeoffs: state => state.writeoffs,
		reports: state => state.reports,
		adminInfo: state => state.adminInfo,
		adminToken: state => state.adminToken,
		orders: state => state.orders,
		audits: state => state.audits,
		companies: state => state.companies,
		prefs: state => state.prefs,
		colors: state => state.colors,
		snackbar: state => state.snackbar,
		needReload: state => state.needReload
	},
	mutations: {
		clear(state) {
			state.production = []
			state.purchases = []
			state.transfers = []
			state.writeoffs = []
			state.orders = []
			state.audits = []
			state.companies = []
		},
		set(state, parameters) {
			if (parameters.subkey !== undefined) {
				state[parameters.key][parameters.subkey] = parameters.value
			}
			else {
				state[parameters.key] = parameters.value
			}
		},
		snackbarAdd(state, text) {
			let id = state.snackNum
			state.snackbar.unshift({
				id: id,
				text: text
			})
			state.snackNum++

			setTimeout(() => {
				state.snackbar = state.snackbar.filter(s => s.id != id)
			}, 5000)
		}
	},
	actions: {
		clear(context) {
			context.commit('clear')
			context.commit('storages/clear')
			context.commit('statuses/clear')
			context.commit('materials/clear')
			context.commit('products/clear')
			context.commit('resources/clear')
			context.commit('categories/clear')
			context.commit('suppliers/clear')
		},
		set(context, parameters) {
			context.commit('set', parameters)
		},
		snackbar(context, text) {
			context.commit('snackbarAdd', text)
		},
		loadInitialData(context, force = false) {
			context.dispatch('storages/getNames', force)
			context.dispatch('statuses/getList', force)
		},
		login(context, data) {
			if (data.token) {
				localStorage.setItem("token", data.token)
			}

			context.dispatch("set", {
				key: "user",
				value: data.user,
			})
			context.dispatch("set", {
				key: "authorized",
				value: true,
			})

			// Load initial data (storages, statuses)
			context.dispatch("loadInitialData")

			// Amplitude set user id
			setUserId(data.user.email)

			// Amplitude set user property authorized to 1
			const identifyObj = new Identify()
			identifyObj.set("authorized", 1)
			identify(identifyObj)

			// Carrotquest set user id and update properties
			if (data.carrotquestHash) {
				// hash не передается если email заканчивается на @controlata.ru
				carrotquest.auth(data.user.id, data.carrotquestHash)
				carrotquest.identify([
					{
						op: "update_or_create",
						key: "$email",
						value: data.user.email,
					},
					{
						op: "update_or_create",
						key: "$name",
						value: data.user.name,
					},
					{
						op: "update_or_create",
						key: "company_admin",
						value: data.user.companyAdmin,
					},
					{
						op: "update_or_create",
						key: "company_id",
						value: data.user.companyId,
					},
					{
						op: "update_or_create",
						key: "company_name",
						value: data.user.companyName,
					},
					{
						op: "update_or_create",
						key: "plan",
						value: data.user.plan,
					},
					{
						op: "update_or_create",
						key: "trial_days",
						value: data.user.trialDays,
					},
				])
			}
		},
		logout(context) {
			localStorage.removeItem('token')

			context.dispatch("set", {
				key: "user",
				value: null,
			})
			context.dispatch('set', {
				key: "authorized",
				value: false
			})
			context.dispatch("clear")

			if (router.currentRoute.path != '/login') {
				router.push('/login')
			}

			const identifyObj = new Identify()
			identifyObj.set('authorized', 0)
			identify(identifyObj)
		}
	}
})
