<template>
	<div>
		<v-btn
			v-if="user?.plan == 'trial' && user?.pastdue > 0"
			color="primary"
			elevation="0"
			@click="openPricing"
		>
			Выбрать тариф
		</v-btn>
		<v-card v-else class="rounded-lg pa-3">
			<v-card-title> Тариф и оплата </v-card-title>
			<v-row v-if="!user">
				<v-col cols="12">
					<v-skeleton-loader type="list-item@2"></v-skeleton-loader>
				</v-col>
			</v-row>
			<v-card-text v-else class="text--primary">
				<!-- Если пробный период -->
				<v-row v-if="user.plan == 'trial'">
					<v-col v-if="user?.trialDays > 0" cols="12" class="text-body-2">
						Пробный период закончится через {{ user?.trialDays }}
						{{ daysSuffix(user?.trialDays) }}.
					</v-col>
					<v-col v-else cols="12" class="text-body-2"> Пробный период закончился. </v-col>
				</v-row>
				<!-- Если тариф архивный и еще идет загрузка -->
				<v-row v-else-if="!user.planStatus && nextPlan.loading">
					<v-col cols="12">
						<v-skeleton-loader type="list-item@2"></v-skeleton-loader>
					</v-col>
				</v-row>
				<!-- Если тариф архивный и загрузка кончилась -->
				<v-row v-else-if="!user.planStatus">
					<v-col cols="12" md="4" v-if="!user.cancel">
						<div class="caption">Тариф c {{ user.nextPaymentDate.replace(/-/g, ".") }}</div>
						<div class="text-body-1">
							{{ nextPlan.title }}
						</div>
					</v-col>
					<v-col cols="12" md="4">
						<div class="caption">Подписка</div>
						<div class="text-body-1">
							{{ user.cancel ? "Отменена" : "Активна" }}
						</div>
					</v-col>
					<v-col cols="12" md="4" v-if="!user.cancel">
						<div class="caption">Оплата</div>
						<div class="text-body-1">
							{{ periodDescription }}
						</div>
					</v-col>
					<v-col cols="12" md="4" v-if="!user.cancel">
						<div class="caption">Стоимость в месяц</div>
						<div class="text-body-1">{{ nextPlan.price }} руб.</div>
					</v-col>
					<v-col cols="12" md="4">
						<div class="caption" v-if="user.cancel">Дата окончания платной подписки</div>
						<div class="caption" v-else>Дата следующей оплаты</div>
						<div :class="user.pastdue ? 'red--text text-body-1' : 'text-body-1'">
							{{ user.nextPaymentDate.replace(/-/g, ".") }}
						</div>
					</v-col>
				</v-row>
				<!-- В другом случае -->
				<v-row v-else>
					<v-col cols="12" md="4">
						<div class="caption">Тариф</div>
						<div class="text-body-1">
							{{ user.planTitle }}
						</div>
					</v-col>
					<v-col cols="12" md="4" v-if="user.price > 0">
						<div class="caption">Подписка</div>
						<div class="text-body-1">
							{{ user.cancel ? "Отменена" : "Активна" }}
						</div>
					</v-col>
					<v-col cols="12" md="4" v-if="!user.cancel && user.price > 0">
						<div class="caption">Оплата</div>
						<div class="text-body-1">
							{{ periodDescription }}
						</div>
					</v-col>
					<v-col cols="12" md="4" v-if="!user.cancel && user.price > 0">
						<div class="caption">Стоимость в месяц</div>
						<div class="text-body-1">{{ user.price }} руб.</div>
					</v-col>
					<v-col cols="12" md="4" v-if="user.price > 0">
						<div class="caption" v-if="user.cancel">Дата окончания платной подписки</div>
						<div class="caption" v-else>Дата следующей оплаты</div>
						<div :class="user.pastdue ? 'red--text text-body-1' : 'text-body-1'">
							{{ user.nextPaymentDate.replace(/-/g, ".") }}
						</div>
					</v-col>
					<v-col>
						<div class="caption">
							Персональный промокод
							<v-tooltip bottom max-width="500">
								<template v-slot:activator="{ on, attrs }">
									<v-icon v-bind="attrs" v-on="on" small> mdi-information-outline </v-icon>
								</template>
								<p>
									Приглашайте друзей и коллег воспользоваться Controlata и получите
									дополнительный месяц к своей подписке!
								</p>
								<ol>
									<li>Отправьте ваш персональный промокод другу.</li>
									<li>
										Друг должен ввести указанный промокод при оформлении платной подписки
										в Controlata.
									</li>
									<li>
										После активации подписки вы и ваш друг получите дополнительный
										бесплатный месяц подписки.
									</li>
								</ol>
							</v-tooltip>
						</div>
						<div v-if="loading.promocode">
							<v-skeleton-loader type="heading"></v-skeleton-loader>
						</div>
						<div v-else class="text-body-1 mt-n1">
							<v-row no-gutters class="align-center">
								<v-col cols="auto">
									{{ promocode }}
								</v-col>
								<v-col cols="auto">
									<v-btn small icon class="ml-2" @click="copyPromocode">
										<v-icon small> mdi-content-copy </v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</div>
					</v-col>
				</v-row>
			</v-card-text>

			<!-- Кнопки -->

			<!-- Триал -->
			<v-row class="pa-5" v-if="user?.plan == 'trial'">
				<!-- Оформить подписку -->
				<v-btn small class="ma-1" @click="openPricing" color="primary" elevation="0">
					<v-icon left>mdi-check-decagram</v-icon>
					Оформить подписку
				</v-btn>

				<!-- Счета на оплату -->
				<v-btn
					v-if="user.paymentMethod == 'invoice'"
					small
					outlined
					class="ma-1"
					@click="openInvoices"
				>
					Счета на оплату
				</v-btn>

				<!-- Отзывы -->
				<v-btn v-if="showFeedback" small class="ma-1" @click="dialog.feedback.open = true" outlined>
					<v-icon left small color="primary">mdi-gift</v-icon>
					Месяц в подарок за отзыв
				</v-btn>
			</v-row>
			<!-- Не триал -->
			<v-row class="pa-5" v-else-if="user">
				<!-- При просрочке оплаты -->
				<v-btn
					v-if="user.pastdue"
					small
					class="ma-1"
					color="primary"
					elevation="0"
					@click="openPricingPastDue"
				>
					Оплатить подписку
				</v-btn>

				<!-- Сменить тариф -->
				<v-btn small class="ma-1" @click="openPricing" color="primary" elevation="0">
					Сменить тариф
				</v-btn>

				<!-- Счета на оплату -->
				<v-btn
					v-if="user.paymentMethod == 'invoice'"
					small
					outlined
					class="ma-1"
					@click="openInvoices"
				>
					Счета на оплату
				</v-btn>

				<!-- Вернуть отмененную подписку -->
				<v-btn
					v-if="user.cancel"
					small
					class="ma-1"
					@click="restoreSub"
					color="primary"
					elevation="0"
				>
					Вернуть подписку
				</v-btn>

				<!-- Тарифные лимиты -->
				<v-btn
					v-if="user.plan == 'free' || user.plan == 'base'"
					small
					class="ma-1"
					outlined
					@click="openLimits"
				>
					Тарифные лимиты
				</v-btn>

				<!-- Отзывы -->
				<v-btn v-if="showFeedback" small class="ma-1" @click="dialog.feedback.open = true" outlined>
					<v-icon left small color="primary">mdi-gift</v-icon>
					Месяц в подарок за отзыв
				</v-btn>

				<!-- Отменить подписку -->
				<v-btn v-if="user.price > 0 && !user.cancel" small class="ma-1" outlined @click="openCancel">
					Отменить подписку
				</v-btn>
			</v-row>
		</v-card>

		<!-- Диалог отмены подписки -->
		<v-dialog
			v-model="dialog.cancel.open"
			max-width="600"
			:fullscreen="mobile"
			:transition="mobile ? false : 'dialog-transition'"
			@click:outside="dialog.cancel.open = false"
			@keydown.esc="dialog.cancel.open = false"
		>
			<v-card v-if="dialog.cancel.step == 1">
				<v-card-title> Отмена подписки </v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" class="red--text pb-0"> Внимание! </v-col>
						<v-col cols="auto">
							После отмены подписки вы будете переведены на бесплатный тариф. Этот тариф
							предоставляет ограниченный набор функций и ограничение в
							{{ plans.free.materialsLimit }} материалов,
							{{ plans.free.productsLimit }} продуктов, {{ plans.free.storagesLimit }} склад и
							{{ plans.free.usersLimit }} пользователя. В случае перехода на бесплатный тариф
							все данные, превышающие установленные лимиты, будут удалены.
						</v-col>
						<v-col cols="auto"> Вы уверены, что хотите отменить подписку? </v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-btn
						color="error"
						elevation="0"
						@click="cancelNextStep"
						:loading="dialog.cancel.loading"
					>
						Отменить подписку
					</v-btn>
					<v-btn outlined @click="dialog.cancel.open = false"> Вернуться </v-btn>
				</v-card-actions>
			</v-card>

			<v-card v-else-if="dialog.cancel.step == 2">
				<v-card-title> Отмена подписки </v-card-title>
				<v-card-text>
					<v-row class="justify-center align-center" style="height: 260px">
						<v-col cols="auto">
							<v-progress-circular indeterminate color="primary"></v-progress-circular>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>

			<v-card v-else-if="dialog.cancel.step == 3">
				<v-card-title> {{ user.name }}, нам жаль, что вы уходите! </v-card-title>
				<v-form v-model="dialog.cancel.valid" ref="cancel">
					<v-card-text class="px-6">
						<v-row>
							<v-col cols="12">
								<v-radio-group
									v-model="dialog.cancel.reason"
									label="Расскажите, почему вы решили отменить подписку?"
									hide-details
									class="mt-0"
									:rules="[required]"
								>
									<v-radio
										label="Слишком дорого для меня"
										value="Слишком дорого для меня"
									></v-radio>
									<v-text-field
										v-if="dialog.cancel.reason == 'Слишком дорого для меня'"
										v-model="dialog.cancel.textPrice"
										outlined
										hide-details
										class="pt-2 pb-3"
										label="Какая цена была бы для вас приемлемой?"
										:rules="[required]"
									></v-text-field>
									<v-radio
										label="Сервис не решает мои задачи"
										value="Сервис не решает мои задачи"
									></v-radio>
									<v-text-field
										v-if="dialog.cancel.reason == 'Сервис не решает мои задачи'"
										v-model="dialog.cancel.textFeatures"
										outlined
										hide-details
										class="pt-2 pb-3"
										label="Подскажите, какую задачу вы ожидали решить, но не смогли?"
										:rules="[required]"
									></v-text-field>
									<v-radio
										label="Мой бизнес закрылся"
										value="Мой бизнес закрылся"
									></v-radio>
									<v-radio label="Другая причина" value="Другая причина"></v-radio>
									<v-text-field
										v-if="dialog.cancel.reason == 'Другая причина'"
										v-model="dialog.cancel.textOther"
										outlined
										hide-details
										class="pt-2 pb-3"
										label="Подскажите, почему вы решили отменить подписку?"
										:rules="[required]"
									></v-text-field>
								</v-radio-group>
							</v-col>
						</v-row>
					</v-card-text>
				</v-form>
				<v-card-actions class="pa-4">
					<v-btn color="error" elevation="0" @click="cancelSub" :loading="dialog.cancel.loading">
						Отменить подписку
					</v-btn>
					<v-btn outlined @click="dialog.cancel.open = false"> Вернуться </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Диалог отзывов -->
		<v-dialog
			v-model="dialog.feedback.open"
			max-width="500"
			:fullscreen="mobile"
			:transition="mobile ? false : 'dialog-transition'"
			@click:outside="dialog.feedback.open = false"
			@keydown.esc="dialog.feedback.open = false"
		>
			<v-card>
				<v-card-title> Месяц в подарок за отзыв </v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12">
							Оставьте отзыв на любой из этих площадок, и мы добавим один месяц к вашей подписке
							за каждый отзыв.
						</v-col>
						<!-- <v-col cols="12"> Оставьте отзыв, и мы добавим один месяц к вашей подписке. </v-col> -->
						<v-col cols="12">
							<v-list-item
								two-line
								href="https://startpack.ru/application/controlata"
								target="_blank"
								rel="nofollow"
							>
								<v-list-item-icon>
									<v-icon>mdi-star</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title> Startpack </v-list-item-title>
									<v-list-item-subtitle>
										Агрегатор сервисов для бизнеса
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-list-item
								three-line
								href="https://forms.yandex.com/u/6549e7b93e9d08030bd3f730/"
								target="_blank"
								rel="nofollow"
							>
								<v-list-item-icon>
									<v-icon>mdi-star</v-icon>
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title> Отзыв для сайта </v-list-item-title>
									<v-list-item-subtitle>
										Заполните анкету, и мы опубликуем ваш отзыв на нашем сайте, указав
										ссылку на ваш сайт
									</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-col>
						<v-col cols="12">
							После того, как оставите отзыв, пришлите скриншот отзыва в чат поддержки в правом
							нижнем углу, и мы продлим вашу подписку.
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-btn color="primary" elevation="0" @click="dialog.feedback.open = false">
						Закрыть
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Диалог тарифных лимитов -->
		<v-dialog
			v-model="dialog.limits.open"
			max-width="600"
			:fullscreen="mobile"
			:transition="mobile ? false : 'dialog-transition'"
			@click:outside="dialog.limits.open = false"
			@keydown.esc="dialog.limits.open = false"
		>
			<v-card>
				<v-card-title> Тарифные лимиты </v-card-title>
				<v-card-text class="mt-6">
					<v-row class="align-center">
						<v-col cols="3">Материалы</v-col>
						<v-col cols="9">
							<v-progress-linear
								:value="(dialog.limits.materialsCount / dialog.limits.materialsLimit) * 100"
								height="16"
								rounded
								color="#7DCBFE"
							>
								<span v-if="!dialog.limits.loading" class="caption">
									{{ dialog.limits.materialsCount }} /
									{{ dialog.limits.materialsLimit }}
								</span>
							</v-progress-linear>
						</v-col>
					</v-row>
					<v-row class="align-center">
						<v-col cols="3">Продукты</v-col>
						<v-col cols="9">
							<v-progress-linear
								:value="(dialog.limits.productsCount / dialog.limits.productsLimit) * 100"
								height="16"
								rounded
								color="#7DCBFE"
							>
								<span v-if="!dialog.limits.loading" class="caption">
									{{ dialog.limits.productsCount }} /
									{{ dialog.limits.productsLimit }}
								</span>
							</v-progress-linear>
						</v-col>
					</v-row>
					<v-row class="align-center">
						<v-col cols="3">Склады</v-col>
						<v-col cols="9">
							<v-progress-linear
								:value="(dialog.limits.storagesCount / dialog.limits.storagesLimit) * 100"
								height="16"
								rounded
								color="#7DCBFE"
							>
								<span v-if="!dialog.limits.loading" class="caption">
									{{ dialog.limits.storagesCount }} /
									{{ dialog.limits.storagesLimit }}
								</span>
							</v-progress-linear>
						</v-col>
					</v-row>
					<v-row class="align-center">
						<v-col cols="3">Пользователи</v-col>
						<v-col cols="9">
							<v-progress-linear
								:value="(dialog.limits.usersCount / dialog.limits.usersLimit) * 100"
								height="16"
								rounded
								color="#7DCBFE"
							>
								<span v-if="!dialog.limits.loading" class="caption">
									{{ dialog.limits.usersCount }} /
									{{ dialog.limits.usersLimit }}
								</span>
							</v-progress-linear>
						</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-btn outlined @click="dialog.limits.open = false"> Закрыть </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Диалог выбора тарифа -->
		<v-dialog
			v-model="dialog.pricing.open"
			:max-width="dialog.pricing.step == 1 ? '1050' : '600'"
			:fullscreen="mobile"
			:transition="mobile ? false : 'dialog-transition'"
		>
			<!-- Выбор тарифа -->
			<v-card v-if="dialog.pricing.step == 1">
				<v-card-title class="headline mb-2">
					<v-row dense>
						<v-spacer></v-spacer>
						<v-col cols="auto" style="margin-left: 60px">Тарифы</v-col>
						<v-spacer></v-spacer>
						<v-col cols="auto">
							<v-btn icon @click="dialog.pricing.open = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<!-- Переключатель месяц / год -->
				<v-row class="justify-center align-center">
					<v-col
						cols="auto"
						class="text-body-1 text--primary"
						style="padding-top: 10px; cursor: pointer"
						@click="dialog.pricing.annual = false"
					>
						Оплата за месяц
					</v-col>
					<v-col cols="auto" class="pr-0">
						<v-switch v-model="dialog.pricing.annual" inset class="px-0 mx-0"> </v-switch>
					</v-col>
					<v-col
						cols="auto pl-2"
						class="text-body-1 text--primary"
						style="padding-top: 10px; cursor: pointer"
						@click="dialog.pricing.annual = true"
					>
						Оплата за год
					</v-col>
				</v-row>
				<!-- Выбор тарифов -->
				<v-card-text>
					<v-row>
						<v-col v-for="(plan, name) in plans" :key="name" cols="12" lg="4">
							<v-hover v-slot="{ hover }">
								<v-card
									:elevation="hover ? 5 : 2"
									:style="
										name == 'base'
											? 'border: 2px solid #01579B'
											: 'border: 1px solid transparent'
									"
									rounded="lg"
								>
									<v-card-title> {{ plan.title }} </v-card-title>
									<v-card-subtitle> {{ plan.subtitle }} </v-card-subtitle>

									<v-card-title class="text-h4 align-end">
										{{ dialog.pricing.annual ? plan.price.annual : plan.price.monthly }}
										₽
										<span class="text-body-2"> / месяц </span>
									</v-card-title>

									<v-card-text :style="mobile ? '' : 'height: 320px'">
										<v-list dense>
											<v-list-item
												v-for="(feature, i) in plan.features"
												:key="i"
												class="px-0"
												style="min-height: 30px"
											>
												<v-list-item-icon class="mr-1 my-1">
													<v-icon small>mdi-check</v-icon>
												</v-list-item-icon>
												<v-list-item-content class="text-body-1 py-0">
													{{ feature }}
												</v-list-item-content>
											</v-list-item>
										</v-list>
									</v-card-text>

									<v-card-text
										v-if="!showPlanActions(name) && !mobile"
										style="height: 52px"
									></v-card-text>

									<v-card-text class="text-center" style="height: 54px">
										{{ user.plan == name ? "Текущий тариф" : "" }}
									</v-card-text>

									<v-card-actions v-if="showPlanActions(name)" class="justify-center pa-3">
										<v-btn
											v-if="user.plan != name"
											outlined
											block
											@click="choosePlan(name)"
											:loading="dialog.pricing.loading[name]"
										>
											Выбрать
										</v-btn>
										<v-btn
											v-else-if="user.period == 'monthly'"
											outlined
											block
											@click="choosePlan(name)"
											:loading="dialog.pricing.loading[name]"
										>
											Перейти на годовую оплату
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-hover>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>

			<!-- Подтверждение бесплатного тарифа -->
			<v-card v-else-if="dialog.pricing.step == 2 && dialog.pricing.plan == 'free'">
				<v-card-title class="headline mb-2">
					<v-row dense>
						<v-col cols="auto">Бесплатный тариф</v-col>
						<v-spacer></v-spacer>
						<v-col cols="auto">
							<v-btn icon @click="dialog.pricing.open = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<v-card-text>
					Ограничения бесплатного тарифа
					<v-simple-table dense class="yellow lighten-4 no-hover-table mt-2">
						<template v-slot:default>
							<tbody>
								<tr>
									<td>Лимит материалов</td>
									<td class="text-end">{{ plans.free.materialsLimit }}</td>
								</tr>
								<tr>
									<td>Лимит продуктов</td>
									<td class="text-end">{{ plans.free.productsLimit }}</td>
								</tr>
								<tr>
									<td>Лимит складов</td>
									<td class="text-end">{{ plans.free.storagesLimit }}</td>
								</tr>
								<tr>
									<td>Лимит пользователей</td>
									<td class="text-end">{{ plans.free.usersLimit }}</td>
								</tr>
								<tr>
									<td>Поставки продуктов</td>
									<td class="text-end">Нет</td>
								</tr>
								<tr>
									<td>Заготовки (производство продуктов из продуктов)</td>
									<td class="text-end">Нет</td>
								</tr>
								<tr>
									<td>История событий</td>
									<td class="text-end">Нет</td>
								</tr>
								<tr>
									<td>Отчеты</td>
									<td class="text-end">Нет</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
				<v-card-text>
					Вы можете пользоваться Controlata абсолютно бесплатно с указанными выше ограничениями. В
					любой момент вы сможете перейти на один из платных тарифов.
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-btn
						color="primary"
						elevation="0"
						@click="goFree"
						:loading="this.dialog.pricing.loading.button"
					>
						Перейти
					</v-btn>
					<v-btn outlined @click="dialog.pricing.step = 1"> Назад </v-btn>
				</v-card-actions>
			</v-card>

			<!-- Подтверждение платного тарифа -->
			<v-card v-else-if="dialog.pricing.step == 2 && dialog.pricing.plan != 'free'">
				<v-card-title class="headline mb-2">
					<v-row dense>
						<v-col cols="auto" v-if="dialog.pricing.pastdue">Оплата подписки</v-col>
						<v-col cols="auto" v-else>Оформление подписки</v-col>
						<v-spacer></v-spacer>
						<v-col cols="auto">
							<v-btn icon @click="dialog.pricing.open = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
				<!-- Параметры подписки -->
				<v-card-text>
					Параметры подписки
					<v-simple-table dense class="yellow lighten-4 no-hover-table mt-2">
						<template v-slot:default>
							<tbody>
								<tr>
									<td>Тариф</td>
									<td class="text-end">{{ chosenPlan.title }}</td>
								</tr>
								<tr>
									<td>
										Срок подписки
										{{ dialog.pricing.promo.bonusMonths > 0 ? "по тарифу" : "" }}
									</td>
									<td class="text-end">
										{{ dialog.pricing.annual ? "12 месяцев" : "1 месяц" }}
									</td>
								</tr>
								<template v-if="dialog.pricing.promo.bonusMonths > 0">
									<tr>
										<td>
											Промокод
											<span class="green--text text--darken-2 font-weight-medium">
												{{ dialog.pricing.promo.code }}
											</span>
										</td>
										<td class="text-end">
											+ {{ dialog.pricing.promo.bonusMonths }}
											{{ monthsSuffix(dialog.pricing.promo.bonusMonths) }}
										</td>
									</tr>
									<tr>
										<td>Итого срок подписки</td>
										<td class="text-end">
											{{ totalMonths }}
											{{ monthsSuffix(totalMonths) }}
										</td>
									</tr>
								</template>
								<tr>
									<td>Стоимость за месяц</td>
									<td class="text-end">{{ toStr(dialog.pricing.pricePerMonth, 0) }} ₽</td>
								</tr>
								<tr>
									<td>
										Итого
										<template v-if="dialog.pricing.daysLeft == 0">к оплате</template>
										за
										{{ totalMonths }}
										{{ monthsSuffix(totalMonths) }}
									</td>
									<td class="text-end">{{ toStr(dialog.pricing.subtotal, 0) }} ₽</td>
								</tr>
								<tr v-if="dialog.pricing.daysLeft > 0">
									<td>
										Остаток текущего тарифа ({{ dialog.pricing.daysLeft }}
										{{ daysSuffix(dialog.pricing.daysLeft) }})
									</td>
									<td class="text-end">
										{{ "-" + toStr(dialog.pricing.daysLeftDiscount, 0) }} ₽
									</td>
								</tr>
								<tr v-if="dialog.pricing.promo.discount > 0" class="align-center">
									<td class="align-center">
										Промокод
										<span class="green--text text--darken-2 font-weight-medium">
											{{ dialog.pricing.promo.code }}
										</span>
									</td>
									<td class="text-end">
										{{ "-" + toStr(dialog.pricing.promo.discount, 0) }} ₽
									</td>
								</tr>
								<tr v-if="dialog.pricing.amount != dialog.pricing.subtotal">
									<td>
										К оплате
										<template v-if="dialog.pricing.amount == 1"> * </template>
									</td>
									<td class="text-end">{{ toStr(dialog.pricing.amount, 0) }} ₽</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
				<!-- Как работает зачет -->
				<v-card-text v-if="dialog.pricing.amount == 1" class="caption">
					* В случае, если стоимость неиспользованного периода текущего тарифа больше, чем стоимость
					нового тарифа, то сумма к оплате составит 1 руб.
				</v-card-text>
				<!-- Промокод -->
				<v-card-text class="pb-0">
					<span
						v-if="!dialog.pricing.promo.show"
						class="link-dotted"
						@click="dialog.pricing.promo.show = true"
					>
						У меня есть промокод
					</span>
					<v-row v-else-if="!dialog.pricing.promo.success">
						<v-col cols="6">
							<v-text-field
								v-model="dialog.pricing.promo.input"
								label="Промокод"
								:messages="dialog.pricing.promo.message"
								:hide-details="!dialog.pricing.promo.message"
								outlined
								dense
							></v-text-field>
						</v-col>
						<v-col cols="auto" class="text-center">
							<v-btn
								color="primary"
								height="40"
								text
								@click="checkPromocode"
								:loading="dialog.pricing.loading.promocode"
							>
								Проверить
							</v-btn>
						</v-col>
					</v-row>
				</v-card-text>
				<!-- Способ оплаты -->
				<v-card-text v-if="dialog.pricing.amount > 0">
					<v-radio-group
						label="Способ оплаты"
						hide-details
						class="mb-2"
						v-model="dialog.pricing.paymentMethod"
					>
						<v-radio key="card" label="Банковская карта" value="card" :disabled="false"></v-radio>
						<v-radio
							key="invoice"
							label="Счет на оплату"
							value="invoice"
							:disabled="dialog.pricing.plan !== 'pro' || !dialog.pricing.annual"
						></v-radio>
					</v-radio-group>
					<span v-if="dialog.pricing.plan !== 'pro'" class="caption">
						Оплата по счету доступна только для тарифа "Профессиональный".
					</span>
					<span v-else-if="!dialog.pricing.annual" class="caption">
						Оплата по счету доступна только при оплате за год.
					</span>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-btn
						color="primary"
						elevation="0"
						@click="pay"
						:loading="this.dialog.pricing.loading.button"
					>
						Оплатить
					</v-btn>
					<v-btn outlined v-if="!dialog.pricing.pastdue" @click="dialog.pricing.step = 1">
						Назад
					</v-btn>
				</v-card-actions>
			</v-card>

			<!-- Реквизиты в случае оплаты по счету -->
			<v-card v-else-if="dialog.pricing.step == 3">
				<v-card-title class="headline mb-2">
					<v-row dense>
						<v-col cols="auto">Реквизиты для счета</v-col>
						<v-spacer></v-spacer>
						<v-col cols="auto">
							<v-btn icon @click="dialog.pricing.open = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>

				<v-form v-model="dialog.pricing.credentialsValid" ref="credentials">
					<v-card-text class="px-6">
						<v-row class="align-center">
							<v-col cols="6">
								<v-text-field
									label="ИНН"
									v-model="credentials.inn"
									outlined
									hide-details
									:rules="[required]"
								></v-text-field>
							</v-col>
							<!-- Заполнить по ИНН -->
							<v-col cols="6" class="text-center">
								<v-btn
									color="primary"
									text
									@click="fillByInn"
									:disabled="!credentials.inn"
									:loading="dialog.pricing.loading.inn"
								>
									Заполнить по ИНН
								</v-btn>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="credentials.name"
									label="Наименование организации"
									outlined
									hide-details
									:rules="[required]"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-textarea
									v-model="credentials.address"
									label="Юридический адрес"
									outlined
									hide-details
									auto-grow
									rows="3"
									:rules="[required]"
								></v-textarea>
							</v-col>
						</v-row>
					</v-card-text>
				</v-form>

				<v-card-actions class="pa-4">
					<v-btn
						color="primary"
						elevation="0"
						@click="createAndDownloadInvoice"
						:loading="dialog.pricing.loading.button"
					>
						Скачать счет
					</v-btn>
					<v-btn outlined @click="dialog.pricing.step = 2"> Назад </v-btn>
				</v-card-actions>
			</v-card>

			<!-- Счет скачан -->
			<v-card v-else-if="dialog.pricing.step == 4">
				<v-card-title class="headline mb-2">
					<v-row dense>
						<v-col cols="auto">Готово</v-col>
						<v-spacer></v-spacer>
						<v-col cols="auto">
							<v-btn icon @click="dialog.pricing.open = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>

				<v-card-text> Загрузка счета на оплату сейчас начнется. </v-card-text>

				<v-card-text> После оплаты счета мы обновим ваш тариф в течение 1 рабочего дня. </v-card-text>

				<v-card-text> Спасибо за выбор Controlata! </v-card-text>

				<v-card-actions class="pa-4">
					<v-btn outlined @click="dialog.pricing.open = false"> Закрыть </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- Диалог счетов на оплату -->
		<v-dialog
			v-model="dialog.invoices.open"
			max-width="700"
			:fullscreen="mobile"
			:transition="mobile ? false : 'dialog-transition'"
			@click:outside="dialog.invoices.open = false"
			@keydown.esc="dialog.invoices.open = false"
		>
			<v-card flat>
				<v-card-title class="headline mb-2">
					<v-row dense>
						<v-col cols="auto">Счета на оплату</v-col>
						<v-spacer></v-spacer>
						<v-col cols="auto">
							<v-btn icon @click="dialog.invoices.open = false">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>

				<v-card-text class="px-6 text-body-1">
					<v-row v-if="dialog.invoices.countNew > 0">
						<v-col cols="12">
							После оплаты счета мы обновим ваш тариф в течение 1 рабочего дня. После этого вы
							также сможете скачать акт об оказании услуг.
						</v-col>
						<v-col cols="12">
							Если после оплаты счета прошел 1 рабочий день, но тариф еще не обновился, то
							напишите нам в чат в правом нижнем углу.
						</v-col>
					</v-row>
					<a-invoices-list
						class="mt-8"
						:open="dialog.invoices.open"
						:admin="false"
						:count-new.sync="dialog.invoices.countNew"
					></a-invoices-list>
				</v-card-text>

				<v-card-actions class="px-4 pb-4 pt-10">
					<v-btn outlined @click="dialog.invoices.open = false"> Закрыть </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<style scoped>
.link-dotted {
	text-decoration: none;
	border-bottom: dotted 1px;
	cursor: pointer;
}
:deep(.v-input--switch__thumb) {
	color: #01579b !important;
}
:deep(.v-input--switch__track) {
	background-color: #01579b !important;
}
</style>

<script>
import { mapGetters } from "vuex"
import { track } from "@amplitude/analytics-browser"
import { toStr, daysSuffix, monthsSuffix } from "../../lib/common"
import { required } from "../../lib/rules"
import api from "../../lib/api"
import aInvoicesList from "../common/AInvoicesList.vue"

export default {
	components: {
		aInvoicesList,
	},
	data: () => ({
		dialog: {
			cancel: {
				open: false,
				loading: false,
				step: 1,
				valid: false,
				reason: "",
				textPrice: "",
				textFeatures: "",
				textOther: "",
			},
			feedback: {
				open: false,
			},
			pricing: {
				open: false, // false
				step: 1, // 1
				annual: true,
				paymentMethod: "card",
				plan: "base",
				pricePerMonth: 0,
				subtotal: 0,
				daysLeft: 0,
				daysLeftDiscount: 0,
				amount: 0,
				promo: {
					show: false, // false
					input: "",
					code: "",
					success: false,
					message: "",
					discount: 0,
					bonusMonths: 0,
				},
				pastdue: false,
				loading: {
					free: false,
					base: false,
					pro: false,
					button: false,
					inn: false,
					promocode: false,
				},
				credentialsValid: true,
			},
			limits: {
				open: false,
				loading: false,
				materialsCount: 0,
				productsCount: 0,
				storagesCount: 0,
				usersCount: 0,
				materialsLimit: 0,
				productsLimit: 0,
				storagesLimit: 0,
				usersLimit: 0,
			},
			invoices: {
				open: false,
				valid: false,
				loading: false,
				count: 0,
				countNew: 0,
			},
		},
		plans: {
			free: {
				title: "Бесплатный",
				subtitle: "Подходит для небольших компаний",
				price: {
					monthly: 0,
					annual: 0,
				},
				materialsLimit: 40,
				productsLimit: 20,
				storagesLimit: 1,
				usersLimit: 1,
				features: ["40 материалов", "20 продуктов", "1 склад", "1 пользователь"],
			},
			base: {
				title: "Базовый",
				subtitle: "Для среднего производства",
				price: {
					monthly: 1790,
					annual: 1490,
				},
				materialsLimit: 400,
				productsLimit: 200,
				storagesLimit: 3,
				usersLimit: 3,
				features: [
					"400 материалов",
					"200 продуктов",
					"3 склада",
					"3 пользователя",
					"Заготовки",
					"Поставки продуктов",
					"История",
					"Отчеты",
				],
			},
			pro: {
				title: "Профессиональный",
				subtitle: "Полный доступ без ограничений",
				price: {
					monthly: 2990,
					annual: 2490,
				},
				materialsLimit: 0,
				productsLimit: 0,
				storagesLimit: 0,
				usersLimit: 0,
				features: [
					"Все из базового плана, плюс:",
					"Любое количество материалов",
					"Любое кол-во продуктов",
					"Любое кол-во складов",
					"Любое кол-во пользователей",
					"Возможность оплаты по счету",
				],
			},
		},
		promocode: "",
		loading: {
			promocode: false,
		},
		credentials: {
			inn: "",
			name: "",
			address: "",
		},
		nextPlan: {
			name: "",
			title: "",
			price: "",
			loading: false,
		},
	}),
	computed: {
		mobile() {
			return this.$vuetify.breakpoint.smAndDown
		},
		periodDescription() {
			if (this.user?.period == "monthly") return "Ежемесячно"
			else if (this.user?.period == "annual") return "Ежегодно"
			else return ""
		},
		showFeedback() {
			return this.user.askForFeedback && this.user.activeDays > 7
		},
		chosenPlan() {
			return this.plans[this.dialog.pricing.plan]
		},
		chosenPlanPrice() {
			return this.dialog.pricing.annual ? this.chosenPlan.price.annual : this.chosenPlan.price.monthly
		},
		paymentMethods() {
			return [
				{
					text: "Банковская карта",
					value: "card",
					disabled: false,
				},
				{
					text: "Счет на оплату",
					value: "invoice",
					disabled: this.dialog.pricing.plan !== "pro",
				},
			]
		},
		totalMonths() {
			return (this.dialog.pricing.annual ? 12 : 1) + Number(this.dialog.pricing.promo.bonusMonths)
		},
		...mapGetters({
			user: "user",
		}),
	},
	methods: {
		cancelSub() {
			this.$refs.cancel.validate()

			if (this.dialog.cancel.valid) {
				this.dialog.cancel.loading = true

				api.post("pay/cancel", {
					reason: this.dialog.cancel.reason,
					textPrice: this.dialog.cancel.textPrice,
					textFeatures: this.dialog.cancel.textFeatures,
					textOther: this.dialog.cancel.textOther,
				}).then(() => {
					this.dialog.cancel.open = false
					this.dialog.cancel.loading = false

					var message =
						"Вы успешно отменили свою подписку. " +
						"Мы больше не будем списывать деньги с вашей карты."

					if (this.user.plan != "free") {
						message =
							message +
							" Текущий тариф будет действовать до " +
							this.user.nextPaymentDate.replace(/-/g, ".")
					}

					this.$store.dispatch("dialog/notifyOpen", {
						title: "Подписка отменена",
						message: message,
					})
				})
			}
		},
		cancelNextStep() {
			this.dialog.cancel.step = 2
			setTimeout(() => {
				this.dialog.cancel.step = 3
			}, 1900)
		},
		restoreSub() {
			api.post("pay/restore").then(() => {
				this.$store.dispatch("dialog/notifyOpen", {
					title: "Подписка восстановлена",
					message:
						"Вы успешно восстановили свою подписку. Следующая оплата будет списана " +
						this.user.nextPaymentDate.replace(/-/g, "."),
				})

				track("PRICEPLAN_RESTORE_SUBSCRIPTION")
			})
		},
		fillByInn() {
			this.dialog.pricing.loading.inn = true
			api.post("dadata/fill_by_inn", {
				inn: this.credentials.inn,
			}).then(
				(response) => {
					this.dialog.pricing.loading.inn = false
					this.credentials.name = response.data.name
					this.credentials.address = response.data.address
				},
				() => {
					this.dialog.pricing.loading.inn = false
				}
			)
		},
		showPlanActions(name) {
			if (!this.user) return false
			else if (this.user.plan != name) return true
			else if (this.user.period == "monthly" && this.user.plan != "free") return true
			else return false
		},
		getCredentials() {
			return api.post("user/get_credentials").then((response) => {
				this.credentials = response.data.credentials
			})
		},
		async createAndDownloadInvoice() {
			this.$refs.credentials.validate()

			if (!this.dialog.pricing.credentialsValid) return

			this.dialog.pricing.loading.button = true

			api.post("user/update_credentials", this.credentials)

			let newResponse = await api.post("invoices/new", {
				promocode: this.dialog.pricing.promo.code,
			})

			let invoiceId = newResponse.data.id
			let num = newResponse.data.num
			let date = newResponse.data.date

			let downloadResponse = await api.post(
				"invoices/download_invoice",
				{
					id: invoiceId,
				},
				{
					responseType: "blob",
				}
			)

			this.dialog.pricing.loading.button = false
			this.dialog.pricing.step = 4

			// create file link in browser's memory
			const href = URL.createObjectURL(downloadResponse.data)

			// create "a" HTML element with href to file & click
			const link = document.createElement("a")
			link.href = href
			link.setAttribute("download", `Счет №${num} от ${date}.pdf`)
			document.body.appendChild(link)
			link.click()

			// clean up "a" element & remove ObjectURL
			document.body.removeChild(link)
			URL.revokeObjectURL(href)
		},
		openInvoices() {
			this.dialog.invoices.open = true
		},
		openLimits() {
			this.dialog.limits.loading = true
			this.dialog.limits.open = true
			this.dialog.limits.materialsCount = 0
			this.dialog.limits.productsCount = 0
			this.dialog.limits.storagesCount = 0
			this.dialog.limits.usersCount = 0

			api.post("user/get_limits").then((response) => {
				this.dialog.limits.materialsCount = response.data.materialsCount
				this.dialog.limits.productsCount = response.data.productsCount
				this.dialog.limits.storagesCount = response.data.storagesCount
				this.dialog.limits.usersCount = response.data.usersCount
				this.dialog.limits.materialsLimit = response.data.materialsLimit
				this.dialog.limits.productsLimit = response.data.productsLimit
				this.dialog.limits.storagesLimit = response.data.storagesLimit
				this.dialog.limits.usersLimit = response.data.usersLimit

				this.dialog.limits.loading = false
			})
		},
		openCancel() {
			this.dialog.cancel.open = true
			this.dialog.cancel.step = 1
			this.dialog.cancel.reason = ""
			this.dialog.cancel.textPrice = ""
			this.dialog.cancel.textFeatures = ""
			this.dialog.cancel.textOther = ""
		},
		openPricing() {
			this.dialog.pricing.open = true
			this.dialog.pricing.pastdue = false
			this.dialog.pricing.step = 1
			this.dialog.pricing.annual = true
		},
		async openPricingPastDue() {
			this.dialog.pricing.annual = this.user.period == "annual"

			let response = await this.getPayment(this.user.plan)

			this.dialog.pricing.pricePerMonth = response.data.pricePerMonth
			this.dialog.pricing.subtotal = response.data.subtotal
			this.dialog.pricing.daysLeft = response.data.daysLeft
			this.dialog.pricing.daysLeftDiscount = response.data.daysLeftDiscount
			this.dialog.pricing.amount = response.data.amount

			this.dialog.pricing.open = true
			this.dialog.pricing.pastdue = true

			this.setPlan(this.user.plan)
		},
		enum(plan) {
			// Нумерация тарифов для сравнения

			if (plan == "free") return 0
			else if (plan == "base") return 1
			else if (plan == "pro") return 2
			else if (plan == "full") return 2
			else if (plan == "trial") return 3
			else return 0
		},
		async choosePlan(plan) {
			this.dialog.pricing.loading[plan] = true

			let check = await this.checkLimits(plan)

			if (!check.success) {
				this.dialog.pricing.loading[plan] = false

				if (this.user.plan != "trial") {
					// Если пользователь на платном тарифе, хочет его снизить и не вписывается в лимиты
					// то предлагаем ему самостоятельно удалить лишние данные

					this.$store.dispatch("dialog/notifyOpen", {
						title: "Изменение тарифа",
						message:
							"Чтобы перейти на " +
							this.plans[plan].title +
							" тариф, пожалуйста, удалите все, что превышает лимиты этого тарифа:" +
							"<br><br>" +
							check.overlimit
								.map((item) => {
									return item.name + " - " + item.count + " (лимит - " + item.limit + ")"
								})
								.join("<br>"),
					})
					return
				} else {
					// Если пользователь на триале, выбирает тариф и не вписывается в лимиты
					// то просто предупреждаем, что часть данных будет удалена

					let confirm = await this.$store.dispatch("dialog/confirmOpen", {
						title: "Изменение тарифа",
						message:
							"При переходе на " +
							this.plans[plan].title +
							" тариф, будет удалено все, что превышает лимиты этого тарифа:" +
							"<br><br>" +
							check.overlimit
								.map((item) => {
									return (
										item.name +
										" - " +
										item.count +
										" (лимит - " +
										item.limit +
										", будет удалено - " +
										(item.count - item.limit) +
										")"
									)
								})
								.join("<br>") +
							"<br><br>" +
							"Вы уверены, что хотите перейти на " +
							this.plans[plan].title +
							" тариф и частично удалить ваши данные?",
					})

					if (!confirm) {
						return
					}
				}
			}

			let response = await this.getPayment(plan)

			this.dialog.pricing.pricePerMonth = response.data.pricePerMonth
			this.dialog.pricing.subtotal = response.data.subtotal
			this.dialog.pricing.daysLeft = response.data.daysLeft
			this.dialog.pricing.daysLeftDiscount = response.data.daysLeftDiscount
			this.dialog.pricing.amount = response.data.amount

			this.dialog.pricing.promo.show = false
			this.dialog.pricing.promo.code = ""
			this.dialog.pricing.promo.input = ""
			this.dialog.pricing.promo.success = false
			this.dialog.pricing.promo.message = ""
			this.dialog.pricing.promo.discount = 0
			this.dialog.pricing.promo.bonusMonths = 0

			this.setPlan(plan)

			this.dialog.pricing.loading[plan] = false
		},
		checkLimits(plan) {
			// Проверим, что условия смены тарифа соблюдены

			if (this.enum(this.user.plan) > this.enum(plan) && plan != "pro") {
				// pro не имеет ограничений и проверять нечего

				this.dialog.pricing.loading[plan] = true

				return api.post("user/get_limits").then((response) => {
					this.dialog.pricing.loading[plan] = false
					var overlimit = []
					if (response.data.materialsCount > this.plans[plan].materialsLimit) {
						overlimit.push({
							name: "Материалы",
							count: response.data.materialsCount,
							limit: this.plans[plan].materialsLimit,
						})
					}
					if (response.data.productsCount > this.plans[plan].productsLimit) {
						overlimit.push({
							name: "Продукты",
							count: response.data.productsCount,
							limit: this.plans[plan].productsLimit,
						})
					}
					if (response.data.storagesCount > this.plans[plan].storagesLimit) {
						overlimit.push({
							name: "Склады",
							count: response.data.storagesCount,
							limit: this.plans[plan].storagesLimit,
						})
					}
					if (response.data.usersCount > this.plans[plan].usersLimit) {
						overlimit.push({
							name: "Пользователи",
							count: response.data.usersCount,
							limit: this.plans[plan].usersLimit,
						})
					}

					if (overlimit.length > 0) {
						return {
							success: false,
							overlimit: overlimit,
						}
					} else {
						return {
							success: true,
						}
					}
				})
			} else {
				return {
					success: true,
				}
			}
		},
		getPayment(plan, promocode = "") {
			return api.post("pay/calculate", {
				plan: plan,
				annual: this.dialog.pricing.annual,
				promocode: promocode,
			})
		},
		async checkPromocode() {
			this.dialog.pricing.loading.promocode = true

			let response = await this.getPayment(this.dialog.pricing.plan, this.dialog.pricing.promo.input)

			this.dialog.pricing.pricePerMonth = response.data.pricePerMonth
			this.dialog.pricing.subtotal = response.data.subtotal
			this.dialog.pricing.daysLeft = response.data.daysLeft
			this.dialog.pricing.daysLeftDiscount = response.data.daysLeftDiscount
			this.dialog.pricing.amount = response.data.amount

			if (response.data.promocodeSuccess) {
				this.dialog.pricing.promo.code = this.dialog.pricing.promo.input.toUpperCase()
			}

			this.dialog.pricing.promo.success = response.data.promocodeSuccess
			this.dialog.pricing.promo.message = response.data.promocodeMessage
			this.dialog.pricing.promo.discount = response.data.promocodeDiscount
			this.dialog.pricing.promo.bonusMonths = response.data.promocodeBonusMonths

			this.dialog.pricing.loading.promocode = false
		},
		setPlan(plan) {
			this.dialog.pricing.plan = plan
			this.dialog.pricing.paymentMethod = "card"
			this.dialog.pricing.step = 2
		},
		pay() {
			this.dialog.pricing.loading.button = true

			if (this.dialog.pricing.paymentMethod == "card") {
				api.post("pay/new", {
					plan: this.dialog.pricing.plan,
					annual: this.dialog.pricing.annual,
					promocode: this.dialog.pricing.promo.code,
				}).then((response) => {
					// Открываем ссылку в текущей вкладке
					window.location.href = response.data.paymentUrl
				})
			} else {
				this.getCredentials().then(() => {
					this.dialog.pricing.loading.button = false

					this.dialog.pricing.step = 3
				})
			}
		},
		goFree() {
			this.dialog.pricing.loading.button = true

			if (this.user.plan == "trial") {
				api.post("pay/go_free").then(() => {
					this.dialog.pricing.open = false
					this.dialog.pricing.loading.button = false

					this.$store.dispatch("dialog/notifyOpen", {
						title: "Готово",
						message: "Вы успешно перешли на бесплатный тариф.",
					})
				})
			} else {
				this.dialog.pricing.open = false
				this.dialog.pricing.loading.button = false
				this.openCancel()
			}
		},
		copyPromocode() {
			navigator.clipboard.writeText(this.promocode)
			this.$store.dispatch("snackbar", "Промокод скопирован")

			track("PERSONAL_PROMOCODE_COPY")
		},
		getPromocode() {
			this.loading.promocode = true
			api.post("pay/get_promocode")
				.then((response) => {
					this.promocode = response.data.promocode
				})
				.finally(() => {
					this.loading.promocode = false
				})
		},
		monthsSuffix,
		daysSuffix,
		required,
		toStr,
	},
	created() {
		this.getPromocode()

		if (!this.user.planStatus) {
			this.nextPlan.loading = true
			api.post("user/get_next_plan").then((response) => {
				this.nextPlan.name = response.data.name
				this.nextPlan.title = response.data.title
				this.nextPlan.price = response.data.price
				this.nextPlan.loading = false
			})
		}
	},
}
</script>
